import * as React from "react";
import {
  Container
} from "@chakra-ui/react";
import {graphql, useStaticQuery} from "gatsby";
import Layout from '../components/Layout'
import PageBody from '../components/PageBody'
import FeatureCard from '../components/FeatureCard'
import Gallery from '../components/Gallery'
// import WithSubnavigation from "../components/NavBar";

// styles

// markup

const AboutPageTemplate = (props) => {
  return (
    <div>
      <PageBody
        extraComponent={
          <Container maxW="container.lg" pb={12}>
            <h2 style={{fontSize: '18pt', textAlign: 'center'}}>OUR GALLERY</h2>
            <Gallery images={props.images}/>
          </Container>
        }
        content={<FeatureCard {...props} />}
      />
    </div>
  );
}

const AboutPage = () => {
  const {directus} = useStaticQuery(pageQuery);
  const page = directus.aboutPage;
  const images = directus.galleryImages;
  return (
    <Layout meta={page.defaultSeo || false} title={page.title || false}>
      <AboutPageTemplate {...page} images={images} />
    </Layout>
  );
};

const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query AboutPage {
    directus {
      aboutPage {
        featureContent
        featureImage {
          id
        }
        defaultSeo {
          metaTitle
          metaImage {
            id
          }
          metaDescription
        }
      }
      galleryImages {
        id
        image {
          id
        }
      }
    }
  }
`;

export default AboutPage;
